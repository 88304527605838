import React from 'react';
import EnovateLogo from '../../assets/icon/logo-enovate-black.png';
import CustomIcon from '../../components/CustomIcon';
import { UserOutlined, BulbOutlined } from '@ant-design/icons';
import { Avatar, Collapse, Tooltip } from 'antd';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { LLM_UNRESOLVED_RESPONSES } from '../../constants';

const areCitationsNotEmpty = (citations) => {
    return citations?.some(citation => !!citation?.url)
}

const shouldHideCitationForUnknownLLMResponses = (content) => {
    const unknownResponses = LLM_UNRESOLVED_RESPONSES.concat(LLM_UNRESOLVED_RESPONSES.map(res => `${res}.`));
    return unknownResponses.includes(content)
}

const ChatMessage = ({ role, content, citations = [], streamingMessage = false, thoughtProcessData = [], handleThoughtProcess }) => {
    const roleSpecificClassNames = role === 'user' ? 'justify-end ' : '';
    const chatContentClassNames = role === 'user' ? 'bg-pc-maroon p-2 rounded-lg' : '';
    const uniqueCitations = [
        ...new Map(citations?.map?.(citation => [citation?.url?.split('?')?.[0], citation])).values()
    ];

    return (
        <div className='chat-message-container my-8'>
            <div className={`chat-message flex flex-row items-start ${roleSpecificClassNames}`}>
                {role === 'assistant' ? <div className='chat-icon-assistant mr-4'>
                    <CustomIcon size={32} src={EnovateLogo} alt="Small Logo for Enovate AI" fixedSizeCustomImage />
                </div> :
                    ''}
                <div className={`chat-content ${chatContentClassNames} flex flex-col ${role === 'assistant' && thoughtProcessData?.length ? 'w-[100%]' : 'default'}`}>
                    <div className='flex flex-row-reverse justify-between items-start'>
                        {thoughtProcessData?.length && role === 'assistant' && !streamingMessage ?
                            (<Tooltip title="Show Thought Process">
                                <BulbOutlined
                                    onClick={() => handleThoughtProcess(thoughtProcessData)} />
                            </Tooltip>) : ''}
                        <div>
                            <Markdown className={role === 'user' ? 'px-1' : ''} remarkPlugins={[remarkGfm]}>{content}</Markdown>
                        </div>
                    </div>
                    {(content && uniqueCitations.length > 0 && !streamingMessage && areCitationsNotEmpty(uniqueCitations)) && !shouldHideCitationForUnknownLLMResponses(content) && (
                        <div className='citations-container'>
                            <Collapse
                                rootClassName='citations-collapsible'
                                ghost
                                expandIconPosition='end'
                                bordered={false}
                                items={[
                                    {
                                        key: '1',
                                        label: 'Citations',
                                        children: <ul className='citations-list'>
                                            {uniqueCitations.map((citation, index) => (
                                                citation.url ? (<li key={index} className='citation my-2 flex'>
                                                    <strong><Markdown>{citation.title || 'Untitled'}</Markdown></strong>
                                                    {citation.url && <a className='px-4 underline text-blue-500 hover:text-blue-400' href={citation.url} target="_blank" rel="noopener noreferrer">View Source</a>}
                                                </li>)
                                                    : ''
                                            ))}
                                        </ul>,
                                    },
                                ]}
                            >
                            </Collapse>
                        </div>
                    )}
                </div>

                {role === 'user' ? <div className='chat-icon-user ml-4'>
                    <Avatar size={32} icon={<UserOutlined />} />
                </div> :
                    ''}
            </div>

        </div>
    )
}

export default ChatMessage