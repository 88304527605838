import React, { useState, useContext } from 'react';
import { Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChatContext } from '../../context/chat/ChatContext';


const Chat = () => {
    const [value, setValue] = useState('');
    const { updateChatMessages, isGeneratingMessage, isStreamingInProgress } = useContext(ChatContext);
    const shouldDisableChat = isGeneratingMessage || isStreamingInProgress;
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async () => {
        const chatMessage = value;
        if (!chatMessage || chatMessage?.trim === '' || shouldDisableChat) {
            return;
        }
        updateChatMessages(chatMessage);
        setValue('');
        if (location.pathname !== '/messages') {
            navigate('/messages')
        }

    }

    const updateInputValue = (e) => {
        setValue(e.target.value)
    }

    return (
        <div className='chat-container flex flex-col  w-90p md:80p lg:w-60p'>
            <Input
                className='rounded-full'
                size="large"
                placeholder="Send message"
                value={value}
                onChange={updateInputValue}
                onPressEnter={handleSubmit}
                disabled={shouldDisableChat}
                // prefix={(
                //     <FileUploader />
                // )}
                suffix={<SendOutlined className='-rotate-45' onClick={handleSubmit}
                />} />
            <div className='disclaimer-message-container'>
                <p className='disclaimer-message px-3 py-1 text-xs text-gray-400 text-center'>
                    <InfoCircleOutlined className='mx-1' />
                    This is AI-Generated Content
                </p>
            </div>
        </div>
    )
}

export default Chat