import React, { useState } from 'react';
import { message } from 'antd';
import { ChatContext } from './ChatContext';
import { config } from '../../config/config';
import { ENOVATE_SUPPORT_EMAIL } from '../../constants';

const successStatusCodes = [200, 201];

const isValidJson = (jsonString) => {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (e) {
        return false;
    }
}

export const ChatProvider = ({ children }) => {
    const savedMessages = JSON.parse(localStorage.getItem('messages'));
    const [messages, setMessages] = useState(savedMessages || []);
    const updateChatMessages = async (userChatMessage) => {
        setMessages(prevMessages => (
            [
                ...prevMessages,
                {
                    role: 'user',
                    content: userChatMessage
                }
            ]
        ))

        const options = {
            method: "POST",
            headers: {
                "api-key": config.AZURE_OPENAI_API_KEY,
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                model: config.CHAT_COMPLETIONS_DEPLOYMENT_NAME,
                messages: [{ role: 'user', content: userChatMessage }],
                stream: true,
                data_sources: [
                    {
                        type: "azure_search",
                        parameters: {
                            endpoint: config.AZURE_SEARCH_ENDPOINT,
                            index_name: config.AZURE_SEARCH_INDEX,
                            authentication: { type: "api_key", key: config.AZURE_SEARCH_API_KEY },
                            query_type: "vector_semantic_hybrid",
                            embedding_dependency: { type: "deployment_name", deployment_name: config.AZURE_OPENAI_EMBEDDING_DEPLOYMENT },
                            semantic_configuration: config.AZURE_SEMANTIC_CONFIGURATION || 'mySemanticConfig'
                        }
                    }
                ]
            })
        }

        try {
            const response = await fetch(`${config.AZURE_OPENAI_ENDPOINT}/openai/deployments/${config.CHAT_COMPLETIONS_DEPLOYMENT_NAME}/chat/completions?api-version=2024-02-01`, options);
            if (!response?.ok && !successStatusCodes.includes(response?.status)) {
                // setMessages(prevMessages => prevMessages.slice(0, -1));
                const errResponse = await response?.json?.();
                const errorCode = Number(errResponse?.error?.code);
                if (errorCode === 400 || errorCode === 429) {
                    const errMessage = errResponse?.error?.message;
                    const errorTimeLimit = errMessage?.match?.(/Try again in\s*(\d+)/)?.[1] || errMessage?.match?.(/Please retry after\s*(\d+)/)?.[1] || 60;
                    throw new Error(` It seems we've reached our request limit for now. Please wait ${errorTimeLimit} seconds and try again. `)
                }
                throw new Error(" Something went wrong, Please try again later! ")
            }
            let streamingText = '';
            const reader = response?.body?.getReader();
            while (true) {
                const { done, value } = await reader?.read();
                if (done) break;

                const decodedChunk = new TextDecoder().decode(value);
                streamingText += decodedChunk;
                const lines = streamingText.split("\n")?.filter(text => text !== '');
                setMessages(prevMessages => {
                    const lastElement = prevMessages.at(-1);
                    if (lastElement?.role !== 'user') {
                        return prevMessages.slice(0, -1)
                    }
                    return prevMessages
                })

                for (const line of lines) {

                    if (line?.startsWith('data: ') && !line?.endsWith('[DONE]')) {
                        const dataString = line?.replace(/^data: /, "").trim();
                        if (isValidJson(dataString)) {
                            const data = JSON.parse(dataString);
                            const currentChunk = data?.choices?.[0]?.delta;
                            const currentContent = currentChunk?.content || '';
                            const currentCitations = currentChunk?.context?.citations;
                            //setMessages(prev => [...prev, { role: 'assistant', content: currentResponse }]);
                            setMessages(prevMessages => {
                                const prevChunk = prevMessages?.find(msg => msg?.id === data?.id)
                                if (!prevChunk) {
                                    return [
                                        ...prevMessages,
                                        {
                                            id: data.id,
                                            role: currentChunk?.role,
                                            citations: currentCitations,
                                            content: currentContent,
                                            streamingMessage: true
                                        }
                                    ]
                                } else {
                                    return prevMessages?.map(prevMessage => {
                                        if (prevMessage?.id === data?.id) {
                                            return {
                                                ...prevMessage,
                                                content: prevMessage.content + currentContent,
                                            }
                                        }
                                        return {
                                            ...prevMessage,
                                            streamingMessage: false
                                        }
                                    })
                                }
                            })
                        }
                    } if (line?.endsWith('[DONE]')) {
                        setMessages(prevMessages => prevMessages.map(msg => ({
                            ...msg,
                            streamingMessage: false
                        })))
                    }
                }
            }

        } catch (err) {
            const errorConfig = {
                content: <div className='text-left'>
                    <p>{err.message}</p>
                    <p>If the error persists, please contact
                        <a className='mx-2 text-blue-500 hover:text-blue-400' href={`mailto:${ENOVATE_SUPPORT_EMAIL}`}>{ENOVATE_SUPPORT_EMAIL}</a>
                    </p>
                </div>,
                duration: 10,
                className: 'chat-error'
            }
            message.error(errorConfig);
        }
    }

    return (
        <ChatContext.Provider value={{
            messages,
            updateChatMessages
        }}>
            {children}
        </ChatContext.Provider>
    )
}
