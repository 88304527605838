import EnovateLogo from '../assets/icon/logo-enovate.png';
import B4ELogo from '../assets/icon/logo-b4e.png';

export const navbarLinks = [{
    navBarLinkLogo: EnovateLogo,
    navBarLinkBrandName: 'ADA AI',
    navBarLinkText: 'Drilling',
    navBarLinkAltText: 'Logo for ADA AI Drilling'
}, {
    navBarLinkLogo: EnovateLogo,
    navBarLinkBrandName: 'ADA AI',
    navBarLinkText: 'New Energy',
    navBarLinkAltText: 'Logo for ADA AI New Energy'
}, {
    navBarLinkLogo: EnovateLogo,
    navBarLinkBrandName: 'ADA AI',
    navBarLinkText: 'Production',
    navBarLinkAltText: 'Logo for ADA AI Production'
}, {
    navBarLinkLogo: B4ELogo,
    navBarLinkText: 'B4E Carbon',
    navBarLinkAltText: 'Logo for B4E Carbon'
}
]