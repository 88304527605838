import { Tag } from "antd";
import { timelineHeaderData } from "../../data/timeline"
const TimelineItem = ({ timelineData }) => {
    const paramsSet = timelineData?.params ? Object.entries(timelineData.params) : [];
    const paramsTags = paramsSet?.map(param => {
        const [name, value] = param;
        return `${name} : ${value}`
    })

    return (
        <div>
            <h2 className='text-blue-500 font-bold text-lg mb-3 leading-none'>
                {timelineHeaderData[timelineData?.key]}
            </h2>
            {paramsTags?.length ? <div> {paramsTags.map(tag => <Tag className="p-2 mb-2 mr-2 leading-none bg-gray-100 rounded-[10px]">{tag}</Tag>)}</div> : ''}
            <div className='max-h-48 overflow-y-auto bg-gray-100 p-4'>
                <pre className='text-pretty'>{JSON.stringify(timelineData?.content, null, 2)}</pre>
            </div>
        </div>
    )
}

export default TimelineItem