import React from 'react';
import { Button } from 'antd';
import { navbarLinks } from '../constants/navbarData';
import CustomIcon from '../components/CustomIcon';

function NavbarLinks() {
    return (
        <div className='navbar-links-container grid grid-cols-2 gap-2 md:gap-4 lg:grid-cols-4 lg:gap-1'>
            {navbarLinks.map(navbarLink => (
                <Button
                    key={navbarLink.navBarLinkText}
                    className='mx-1 text-xs md:mx-2 md:text-sm font-bold py-4 px-2 md:px-4 my-2 lg:my-0'
                    size='middle'
                    shape='round'
                    icon={<CustomIcon src={navbarLink.navBarLinkLogo} alt={navbarLink.navBarLinkAltText} />}
                >
                    <span>
                        {navbarLink.navBarLinkBrandName ?
                            <span className='pr-1'>
                                {navbarLink.navBarLinkBrandName}
                                <sup className='-top-2'>TM</sup>
                            </span> : ''}
                        {navbarLink.navBarLinkText}
                        {!navbarLink.navBarLinkBrandName ? <sup className='-top-2'>TM</sup> : ''}
                    </span>
                </Button>
            ))}
        </div>
    )
}

export default NavbarLinks