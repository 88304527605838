import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Menu } from 'antd';


const UserProfile = () => {
    return (
        <div className='user-profile flex flex-row'>
            <Menu
                mode='inline'
                items={[{
                    label: <div className='user-info-container flex flex-row items-center p-1'>
                        <Avatar shape="square" size={40} icon={<UserOutlined />} />
                        <div className='hidden lg:flex flex-col p-1 leading-none'>
                            <h3 className='font-bold mb-1'>
                                Susan Collins
                            </h3>
                            <span>
                                Operator Co.
                            </span>
                        </div>
                    </div>,
                    children: [
                        { label: 'Settings', key: 'settings' },
                        { label: 'Signout', key: 'signout' }
                    ]
                }]}>
            </Menu>
        </div>
    )
}

export default UserProfile