import React, { useEffect, useContext, useRef, useState } from 'react';
import { ChatContext } from '../context/chat/ChatContext';
import ChatMessage from '../components/chat/ChatMessage';
import { Skeleton } from 'antd';
import APITimelineDrawer from '../components/APITimelineDrawer';

const initialThoughtProcessData = {
    showThoughtProcessTimeline: false,
    thoughtProcessTimelineData: null
}

const Messages = () => {
    const { messages, isGeneratingMessage } = useContext(ChatContext);
    const messageEndRef = useRef(null);
    const [currentThoughtProcessData, setCurrentThoughtProcessData] = useState(initialThoughtProcessData);

    const handleThoughtProcess = (thoughtProcessData) => {
        setCurrentThoughtProcessData({
            showThoughtProcessTimeline: true,
            thoughtProcessTimelineData: thoughtProcessData
        })
    }

    useEffect(() => {
        messageEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (
        <div className='messages-scroll-section flex flex-col h-full bg-white w-95p mx-4 px-4 py-8 mb-0 overflow-y-auto rounded-2xl lg:rounded-none shadow-lg lg:shadow-none'>
            <div className='messages-container w-90p lg:w-80p mx-auto'  >
                {messages?.map?.((message, index) => (
                    <ChatMessage
                        key={index}
                        role={message?.role}
                        content={message?.content}
                        citations={message?.citations}
                        streamingMessage={message?.streamingMessage}
                        thoughtProcessData={message?.thoughtProcessData}
                        handleThoughtProcess={handleThoughtProcess}
                    />
                ))}
                {isGeneratingMessage && <Skeleton avatar active />}
            </div>
            <div ref={messageEndRef}></div>
            {currentThoughtProcessData?.showThoughtProcessTimeline ? (
                <APITimelineDrawer
                    open={currentThoughtProcessData?.showThoughtProcessTimeline}
                    onClose={() => setCurrentThoughtProcessData(initialThoughtProcessData)}
                    thoughtProcessTimelineData={currentThoughtProcessData?.thoughtProcessTimelineData} />
            ) : ''}
        </div>
    )
}

export default Messages