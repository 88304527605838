import React from 'react';
import EnovateLogo from '../assets/icon/logo-enovate.png';
import B4ELogo from '../assets/icon/logo-b4e.png';
import ProductTile from './ProductTile';

const productTilesData = [{
    logo: EnovateLogo,
    productTileBrandName: 'ADA AI',
    header: 'Drilling',
    subHeader: 'Automatic Report Generation'
}, {
    logo: EnovateLogo,
    productTileBrandName: 'ADA AI',
    header: 'New Energy',
    subHeader: 'Automatic Report Generation'
}, {
    logo: EnovateLogo,
    productTileBrandName: 'ADA AI',
    header: 'Production',
    subHeader: 'Automatic Report Generation'
}, {
    logo: B4ELogo,
    header: 'B4E Carbon',
    subHeader: 'Automatic Report Generation'
}
]

const ProductTileList = () => {
    return (
        <div className='product-tiles-container grid grid-cols-2 gap-4 mx-8 lg:mx-0 lg:flex lg:flex-row'>
            {
                productTilesData.map(product => (
                    <ProductTile productTileData={product} key={product.header} />
                ))
            }
        </div>
    )
}

export default ProductTileList