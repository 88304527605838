import QuestionTile from "./QuestionTile";
import { chatQuestions } from "../config/config";

const QuestionTileList = () => {
    return (
        <div className='question-tiles-container grid grid-cols-2 gap-4 mx-8 lg:flex lg:flex-row  lg:mx-4'>
            {
                chatQuestions?.map(question => (
                    <QuestionTile question={question} key={question} />
                ))
            }
        </div>
    )
}

export default QuestionTileList