
export const config = {
    AZURE_OPENAI_ENDPOINT: process.env.REACT_APP_AZURE_OPENAI_ENDPOINT,
    AZURE_OPENAI_API_KEY: process.env.REACT_APP_AZURE_OPENAI_API_KEY,
    AZURE_SEARCH_ENDPOINT: process.env.REACT_APP_AZURE_SEARCH_ENDPOINT,
    AZURE_SEARCH_API_KEY: process.env.REACT_APP_AZURE_SEARCH_API_KEY,
    AZURE_SEARCH_INDEX: process.env.REACT_APP_AZURE_SEARCH_INDEX,
    CHAT_COMPLETIONS_DEPLOYMENT_NAME: process.env.REACT_APP_CHAT_COMPLETIONS_DEPLOYMENT_NAME,
    AZURE_OPENAI_EMBEDDING_DEPLOYMENT: process.env.REACT_APP_AZURE_OPENAI_EMBEDDING_DEPLOYMENT,
    AZURE_SEMANTIC_CONFIGURATION: process.env.REACT_APP_AZURE_SEMANTIC_CONFIGURATION
}