import { config } from "../../config/config";
import { CHAT_COMPLETION_API_REQ_PARAMS, SEARCH_QUERY_REQ_PARAMS, SYSTEM_PROMPT_TOP_DOCUMENTS } from "../../constants";
import { getRetryTimeLimit } from "../../utils";

const successStatusCodes = [200, 201];

export const getMessagesWithSearchContext = (chatMessage, topDocuments, prevMessages) => {
    const last10Messages = prevMessages?.slice(-10);
    const last10MessagesForChatCompletion = last10Messages?.filter(message => message?.content && message?.role)?.map(message => ({ role: message.role, content: message.content })) || [];
    const messages = topDocuments?.length ? [
        { role: 'system', content: `${SYSTEM_PROMPT_TOP_DOCUMENTS} Context:${JSON.stringify(topDocuments)}` },
        ...last10MessagesForChatCompletion,
        { role: 'user', content: chatMessage }
    ] : [
        ...last10MessagesForChatCompletion,
        { role: 'user', content: chatMessage }
    ]
    return messages;
}

export const createChatCompletion = async (chatMessage, topDocuments, prevMessages) => {
    try {
        const messages = getMessagesWithSearchContext(chatMessage, topDocuments, prevMessages)
        const options = {
            method: "POST",
            headers: {
                "api-key": config.AZURE_OPENAI_API_KEY,
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                model: CHAT_COMPLETION_API_REQ_PARAMS.model,
                messages,
                stream: CHAT_COMPLETION_API_REQ_PARAMS.stream,
                temperature: CHAT_COMPLETION_API_REQ_PARAMS.temperature
            })
        }
        const response = await fetch(`${config.AZURE_OPENAI_ENDPOINT}/openai/deployments/${config.CHAT_COMPLETIONS_DEPLOYMENT_NAME}/chat/completions?api-version=2024-02-01`, options);
        if (!response?.ok && !successStatusCodes.includes(response?.status)) {
            const errResponse = await response?.json?.();
            const errorCode = Number(errResponse?.error?.code);
            if (errorCode === 400 || errorCode === 429) {
                const errMessage = errResponse?.error?.message;
                const errorTimeLimit = getRetryTimeLimit(errMessage);
                throw new Error(` It seems we've reached our request limit for now. Please wait ${errorTimeLimit} seconds and try again . `)
            }
            throw new Error(" Something went wrong, Please try again later! ")
        }
        return response;
    } catch (error) {
        throw error;
    }
}

export const getTop50DocumentsContext = async (chatMessage) => {
    try {
        const options = {
            method: "POST",
            headers: {
                "api-key": config.AZURE_SEARCH_API_KEY,
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                search: chatMessage,
                queryType: SEARCH_QUERY_REQ_PARAMS.queryType,
                queryLanguage: "en-us",
                semanticConfiguration: SEARCH_QUERY_REQ_PARAMS.semanticConfiguration,
                vectorQueries: [
                    {
                        kind: SEARCH_QUERY_REQ_PARAMS.kind,
                        text: chatMessage,
                        fields: SEARCH_QUERY_REQ_PARAMS.fields,
                        exhaustive: true
                    }
                ],
                select: SEARCH_QUERY_REQ_PARAMS.select,
                top: SEARCH_QUERY_REQ_PARAMS.top
            })
        }
        const response = await fetch(`${config.AZURE_SEARCH_ENDPOINT}/indexes/${config.AZURE_SEARCH_INDEX}/docs/search?api-version=2024-11-01-Preview`, options);
        if (!response?.ok) {
            throw new Error("Unable to retrieve the related documents for the question you have asked");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}