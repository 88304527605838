import { useContext } from 'react';
import { Card, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ChatContext } from '../context/chat/ChatContext';


const QuestionTile = ({ question }) => {
    const { updateChatMessages, isGeneratingMessage, isStreamingInProgress } = useContext(ChatContext);
    const navigate = useNavigate();
    const handleTileClick = async () => {
        if (isGeneratingMessage || isStreamingInProgress) {
            message.warning("Generating a response for the previous query. Please wait until it is complete.");
            return;
        }
        navigate('/messages');
        updateChatMessages(question);

    }
    return (
        <div className='question-tile-container flex flex-col flex-1 min-h-0'>
            <Card size='small' className='shadow-md lg:m-2  lg:p-3 lg:shadow-lg cursor-pointer hover:border-blue-400 flex-1 overflow-auto' onClick={handleTileClick}>
                <p className='my-1 font-semibold'>{question}</p>
            </Card>
        </div>
    )
}

export default QuestionTile