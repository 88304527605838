
export const config = {
    AZURE_OPENAI_ENDPOINT: process.env.REACT_APP_AZURE_OPENAI_ENDPOINT,
    AZURE_OPENAI_API_KEY: process.env.REACT_APP_AZURE_OPENAI_API_KEY,
    AZURE_SEARCH_ENDPOINT: process.env.REACT_APP_AZURE_SEARCH_ENDPOINT,
    AZURE_SEARCH_API_KEY: process.env.REACT_APP_AZURE_SEARCH_API_KEY,
    AZURE_SEARCH_INDEX: process.env.REACT_APP_AZURE_SEARCH_INDEX,
    CHAT_COMPLETIONS_DEPLOYMENT_NAME: process.env.REACT_APP_CHAT_COMPLETIONS_DEPLOYMENT_NAME,
    AZURE_OPENAI_EMBEDDING_DEPLOYMENT: process.env.REACT_APP_AZURE_OPENAI_EMBEDDING_DEPLOYMENT,
    AZURE_SEMANTIC_CONFIGURATION: process.env.REACT_APP_AZURE_SEMANTIC_CONFIGURATION,
    BASE_URL_ACCESS_CONTROL: process.env.REACT_APP_PORT_DB,
}

export const reCAPTCHAConfig = {
    RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    RECAPTCHA_SECRET_KEY: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
}

export const keyIntegration = {
    keyEncrypt: process.env.REACT_APP_KEYENCRYPT,
}

const getChatQuestions = () => {
    const envQuestionData = [
        process.env.REACT_APP_QUESTION1,
        process.env.REACT_APP_QUESTION2,
        process.env.REACT_APP_QUESTION3,
    ]
    let questions = [];
    envQuestionData.forEach(question => {
        if (question) {
            questions.push(question)
        }
    })

    return questions;
}

export const chatQuestions = getChatQuestions();