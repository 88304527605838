import { useEffect, useContext } from "react";
import { ChatContext } from "../context/chat/ChatContext";

export const useBrowserCloseOrReload = () => {
    const { messages } = useContext(ChatContext);
    useEffect(() => {
        const saveMessages = () => {
            const updatedMessagesForStreaming = messages?.map(message => ({ ...message, streamingMessage: false }))
            const chatMessages = JSON.stringify(updatedMessagesForStreaming);
            localStorage.setItem('messages', chatMessages)
        }
        window.addEventListener("beforeunload", saveMessages)
        return () => window.removeEventListener("beforeunload", saveMessages)
    }, [messages])
}

