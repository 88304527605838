import { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { ChatContext } from "../context/chat/ChatContext";

export const useBrowserCloseOrReload = () => {
    const { messages } = useContext(ChatContext);
    const { userData } = useSelector(state => state.configAuth);
    const loggedInUserId = userData?.userId;
    useEffect(() => {
        const saveMessages = () => {
            const updatedMessagesForStreaming = messages?.map(message => ({ ...message, streamingMessage: false, thoughtProcessData: null }));
            if (loggedInUserId) {
                const savedMessages = JSON.parse(localStorage.getItem('messages'));
                let updatedMessages = {};
                if (Array.isArray(savedMessages)) {
                    updatedMessages = { [loggedInUserId]: updatedMessagesForStreaming }
                }
                else {
                    updatedMessages = { ...savedMessages, [loggedInUserId]: updatedMessagesForStreaming }
                }
                localStorage.setItem('messages', JSON.stringify(updatedMessages))
            } else {
                localStorage.setItem('messages', JSON.stringify(updatedMessagesForStreaming))
            }
        }
        window.addEventListener("beforeunload", saveMessages)
        return () => window.removeEventListener("beforeunload", saveMessages)
    }, [messages])
}

