import { config } from "../../config/config";
import { getRetryTimeLimit } from "../../utils";

const successStatusCodes = [200, 201];

export const createChatCompletion = async (chatMessage) => {
    try {
        const options = {
            method: "POST",
            headers: {
                "api-key": config.AZURE_OPENAI_API_KEY,
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                model: config.CHAT_COMPLETIONS_DEPLOYMENT_NAME,
                messages: [{ role: 'user', content: chatMessage }],
                stream: true,
                data_sources: [
                    {
                        type: "azure_search",
                        parameters: {
                            endpoint: config.AZURE_SEARCH_ENDPOINT,
                            index_name: config.AZURE_SEARCH_INDEX,
                            authentication: { type: "api_key", key: config.AZURE_SEARCH_API_KEY },
                            query_type: "vector_semantic_hybrid",
                            embedding_dependency: { type: "deployment_name", deployment_name: config.AZURE_OPENAI_EMBEDDING_DEPLOYMENT },
                            semantic_configuration: config.AZURE_SEMANTIC_CONFIGURATION || 'mySemanticConfig'
                        }
                    }
                ]
            })
        }
        const response = await fetch(`${config.AZURE_OPENAI_ENDPOINT}/openai/deployments/${config.CHAT_COMPLETIONS_DEPLOYMENT_NAME}/chat/completions?api-version=2024-02-01`, options);
        if (!response?.ok && !successStatusCodes.includes(response?.status)) {
            const errResponse = await response?.json?.();
            const errorCode = Number(errResponse?.error?.code);
            if (errorCode === 400 || errorCode === 429) {
                const errMessage = errResponse?.error?.message;
                const errorTimeLimit = getRetryTimeLimit(errMessage);
                throw new Error(` It seems we've reached our request limit for now. Please wait ${errorTimeLimit} seconds and try again . `)
            }
            throw new Error(" Something went wrong, Please try again later! ")
        }
        return response;
    } catch (error) {
        throw error;
    }
}