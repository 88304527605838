import React from 'react'
import { ReactComponent as SidebarLogo } from '../assets/EnovateLogo.svg';
import { Bag } from 'react-bootstrap-icons';
import ReportLinks from '../components/ReportLinks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const naviateToMessages = () => {
        if (location.pathname !== '/messages') {
            navigate('/messages')
        }
    }

    return (
        <div className='sidebar hidden lg:flex flex-col  lg:w-20p p-6 border-r'>
            <div className='logo flex items-center px-1 py-3 mb-8'>
                <SidebarLogo className='scale-75 cursor-pointer' onClick={() => navigate('/')} />
            </div>
            <div className='link-generate-report flex flex-row items-center pb-6 border-b'>
                <Bag className='mr-2' />
                <span>Create general report</span>
            </div>
            <div className='report-links'>
                <ReportLinks />
            </div>
            <div className='chat-history-nav-section my-4'>
                <Button shape="round" size='large' className='border-black' onClick={naviateToMessages}>
                    Chat History
                </Button>
            </div>
        </div>
    )
}

export default Sidebar