import React from 'react';
import EnovateLogo from '../../assets/icon/logo-enovate-black.png';
import CustomIcon from '../../components/CustomIcon';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const areCitationsNotEmpty = (citations) => {
    return citations?.some(citation => !!citation?.url)
}

const ChatMessage = ({ role, content, citations = [], streamingMessage = false }) => {
    const roleSpecificClassNames = role === 'user' ? 'justify-end ' : '';
    const chatContentClassNames = role === 'user' ? 'bg-pc-maroon p-2 rounded-lg' : '';
    const uniqueCitations = [
        ...new Map(citations?.map?.(citation => [citation?.url?.split('?')?.[0], citation])).values()
    ];

    return (
        <div className='chat-message-container my-8'>
            <div className={`chat-message flex flex-row items-start ${roleSpecificClassNames}`}>
                {role === 'assistant' ? <div className='chat-icon-assistant mr-4'>
                    <CustomIcon size={32} src={EnovateLogo} alt="Small Logo for Enovate AI" fixedSizeCustomImage />
                </div> :
                    ''}
                <div className={`chat-content ${chatContentClassNames} flex flex-col`}>
                    <Markdown className={role === 'user' ? 'px-1' : ''} remarkPlugins={[remarkGfm]}>{content}</Markdown>
                    {(uniqueCitations.length > 0 && !streamingMessage && areCitationsNotEmpty(uniqueCitations)) && (
                        <div className='citations-container'>
                            <h3 className='font-bold my-2 text-xl'>Citations:</h3>
                            <ul className='citations-list'>
                                {uniqueCitations.map((citation, index) => (
                                    citation.url ? (<li key={index} className='citation my-3 flex'>
                                        <strong><Markdown>{citation.title || 'Untitled'}</Markdown></strong>
                                        {citation.url && <a className='px-4 underline text-blue-500 hover:text-blue-400' href={citation.url} target="_blank" rel="noopener noreferrer">View Source</a>}
                                    </li>)
                                        : ''
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                {role === 'user' ? <div className='chat-icon-user ml-4'>
                    <Avatar size={32} icon={<UserOutlined />} />
                </div> :
                    ''}
            </div>

        </div>
    )
}

export default ChatMessage