import { config } from "../config/config";

export const ENOVATE_SUPPORT_EMAIL = 'enovate-support@enovate.ai';

export const LLM_UNRESOLVED_RESPONSES = [
    "I don't know",
    "The requested information is not available in the retrieved data. Please try another query or topic",
    `Sorry, we couldn't find any information matching your query. Try rephrasing your question or searching for a different topic to get better results`
]

export const SYSTEM_PROMPT_TOP_DOCUMENTS = `You are a factual assistant who answers questions only using the provided context. Below is the relevant context extracted from trusted sources. Use this context to answer the user's question. If the context does not include the necessary information, simply respond with "Sorry, we couldn't find any information matching your query. Try rephrasing your question or searching for a different topic to get better results"`;

export const SEARCH_QUERY_REQ_PARAMS = {
    queryType: "semantic",
    semanticConfiguration: "default",
    kind: 'text',
    select: "title,text,url",
    top: 25,
    fields: "text_vector"
}

export const CHAT_COMPLETION_API_REQ_PARAMS = {
    model: config.CHAT_COMPLETIONS_DEPLOYMENT_NAME,
    stream: true,
    temperature: 0.2
}