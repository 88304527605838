import { Drawer, Timeline } from 'antd'
import React from 'react'
import TimelineItem from './timeline/TimelineItem';
import { CircleFill } from 'react-bootstrap-icons';

const APITimelineDrawer = ({ open, onClose, thoughtProcessTimelineData }) => {
    const timelineItems = thoughtProcessTimelineData?.map(item => ({
        dot: <div className='p-2 rounded-full bg-[rgba(209,219,250,0.7)]'><CircleFill /></div>,
        children: <TimelineItem timelineData={item} key={item?.key} />
    }))
    return (
        <div>
            <Drawer
                size='large'
                open={open}
                onClose={onClose}>
                <Timeline
                    mode='left'
                    items={timelineItems}
                    rootClassName='thought-process-timeline'
                />
            </Drawer>
        </div>
    )
}

export default APITimelineDrawer;